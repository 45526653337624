import React from 'react';
import './App.sass';
import LeftSidebar from './inc/sidebarLeft.js';
import RightSidebar from './inc/sidebarRight.js';

class App extends React.Component {

  componentDidMount() {
    const getItemHover = document.getElementById('content__contacts')
    const getItemMail = document.getElementById('mailto')
    
    getItemHover.addEventListener('mouseover', () => {
      getItemMail.classList.add('hover')
    });

    getItemHover.addEventListener('mouseleave', () => {
      getItemMail.classList.remove('hover')
    });

    getItemHover.addEventListener('animationiteration', () => {
      setTimeout(() => {
        getItemMail.classList.remove('hover')
      }, 1000)
    });
  }

  render() {
    return (
      <div className="content">
        <div className="content__left">
          <LeftSidebar />
        </div>
        <div className="content__right">
          <RightSidebar />
        </div>
      </div>
    )
  }
}

export default App;
