import React, { Component } from 'react'

class sidebarRight extends Component {
    render() {
        return (
            <>
                <div className="content__right-top">
                <h3>Skills.</h3>
                <ul className="content__skills-list">
                    <li>HTML /5</li>
                    <li>XML</li>
                    <li>CSS /3</li>
                    <li>PHP</li>
                    <li>JSON</li>
                    <li>MYSQL</li>
                    <li>JAVASCRIPT</li>
                    <li>NODE.JS</li>
                    <li>ACADEMIC KNOWLEDGE C</li>
                    <li>C++</li>
                </ul>

                <h3>PHP Frameworks.</h3>
                <ul className="content__skills-list">
                    <li>Symfony</li>
                    <li>Laravel</li>
                    <li>Zend</li>
                </ul>

                <h3>Javascript Libraries/Frameworks.</h3>
                <ul className="content__skills-list">
                    <li>React.js</li>
                    <li>Vue.js</li>
                    <li>jQuery</li>
                </ul>

                <h3>CMS.</h3>
                <ul className="content__skills-list">
                    <li>Wordpress</li>
                    <li>Prestashop</li>
                    <li>Magento</li>
                </ul>
                </div>
                <div className="content__right-bottom"></div>
            </>
        )
    }
}

export default sidebarRight